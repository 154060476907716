<template>
  <div class="flex flex-wrap justify-center w-auto items-center mb-12 -m-1.5">
    <div class="w-full lg:w-72 p-1.5">
      <input
        class="text-gray-500 px-5 py-4 w-full placeholder-gray-500 outline-none focus:ring-4 focus:ring-midGreen rounded"
        type="text"
        placeholder="Enter email"
      />
    </div>
    <div class="w-full lg:w-auto p-1.5">
      <button
        class="group relative font-heading px-6 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold bg-gray-900 hover:bg-gray-800 overflow-hidden rounded-md"
      >
        <p class="relative z-10">Get a head start</p>
      </button>
    </div>
  </div>
</template>
