<template>
  <div class="w-full lg:w-7/12 p-8 lg:p-16">
    <div class="bg-gradient-to-r from-startGrad to-endGrad rounded-2xl">
      <img
        class="relative w-full transform scale-90 hover:scale-110 transition ease-in-out duration-500 rounded-3xl"
        src="@/assets/dash2.png"
        alt=""
      />
    </div>
  </div>
</template>
