
import { defineComponent } from "vue";
import Header from "@/components/Base/Header.vue";
import TitleText from "@/components/Title/Main/Text.vue";
import TitleEmail from "@/components/Title/Main/Email.vue";
import TitleFeatures from "@/components/Title/Feature/Features.vue";
import TitleNewsletter from "@/components/Title/Newsletter/Newsletter.vue";
import Footer from "@/components/Base/Footer.vue";

export default defineComponent({
  name: "Title",
  components: {
    Header,
    TitleText,
    TitleEmail,
    TitleFeatures,
    TitleNewsletter,
    Footer,
  },
});
