<template>
  <h1
    class="mb-10 md:mb-4 font-heading text-center text-white text-4xl md:text-7xl font-bold"
  >
    Start completing your net zero goals.
  </h1>
  <p class="mb-9 font-medium text-xl text-center text-gray-100 mx-6 md:mx-72">
    Introducing a revolutionary marketplace to pair organisation's carbon pain
    points to dedicated solutions.
  </p>
</template>
