
import { defineComponent } from "vue";
import TitleFeatureImage from "./Image.vue";
import TitleFeatureItem from "./Item.vue";

export default defineComponent({
  name: "Title",
  components: {
    TitleFeatureImage,
    TitleFeatureItem,
  },
});
