<template>
  <section class="overflow-hidden bg-gradient-to-r from-startGrad to-endGrad">
    <div class="container mx-auto px-4">
      <img class="mx-auto my-12 h-20" src="@/assets/icon-white.svg" alt="" />
      <ul class="flex flex-wrap justify-center -m-5 pb-8">
        <li class="p-5">
          <a
            class="font-heading text-base text-white hover:text-gray-700"
            href="#features"
            >Features</a
          >
        </li>
        <li class="p-5">
          <a
            class="font-heading text-base text-white hover:text-gray-700"
            href="#register"
            >Register</a
          >
        </li>
      </ul>
      <div class="border-b border-gray-100"></div>
      <p class="text-white text-center py-8 text-sm">
        © Copyright 2022. All Rights Reserved by Branching Software.
      </p>
    </div>
  </section>
</template>
